@config "../../tailwind.config.js";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
@import url('./static/css/main.css');
:root {
  --primary-color: #3498db;
  --menu-color: #9CAB9E;
  --menu-text-color: #ffffff;
  --background-color: #a4b3a6;
  --text-color: #333;
  background-color: #a4b3a6;
}

body {
  margin: 0;
  font-family: 'Crimson Text', 'Oxygen', -apple-system,
    BlinkMacSystemFont,
    'Segoe UI', 'Roboto',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #edeee5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


